import React, { useEffect } from 'react';
import { Capacitor } from '@capacitor/core';
import OneSignal from 'onesignal-cordova-plugin';
import axios from 'axios';

const UpdateOneSignalId = ({ user }) => {
  useEffect(() => {
    if (Capacitor.isNativePlatform() && user) {
      if (
        OneSignal &&
        OneSignal.User &&
        OneSignal.User.pushSubscription &&
        typeof OneSignal.User.pushSubscription.getIdAsync === 'function'
      ) {
        OneSignal.User.pushSubscription.getIdAsync()
          .then(oneSignalIdentifier => {
            console.log("OneSignal Identifier récupéré :", oneSignalIdentifier);
            // Envoie l'ID vers le backend pour mise à jour
            axios.post(process.env.REACT_APP_API_URL + '/v1/onesignal/update', {
              playerId: oneSignalIdentifier,
              userId: user._id.toString()
            })
              .then(response => {
                console.log("Backend mis à jour :", response.data);
              })
              .catch(error => {
                console.error("Erreur lors de la mise à jour sur le backend :", error);
              });
          })
          .catch(error => {
            console.error("Erreur lors de getIdAsync :", error);
          });
      } else {
        console.error("La méthode getIdAsync n'est pas disponible sur OneSignal.User.pushSubscription.");
      }
    }
  }, [user]);

  return null;
};

export default UpdateOneSignalId;
