import React, { useState } from 'react';
import axios from 'axios';

const TestMoneticoPayment = () => {
  const [montant, setMontant] = useState('');
  const [reference, setReference] = useState('');
  const [error, setError] = useState(null);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      // On envoie uniquement les données nécessaires
      const response = await axios.post(process.env.REACT_APP_API_URL + `/v1/payment-request`, {
        montant: '1000',      // montant en centimes
        reference: 'REF124578',    // référence de la commande
      });
      // Supposons que l'API retourne { redirectUrl: '/monetico/payment-form' }

      console.log(response.data);

      const { redirectUrl } = response.data;
      if (redirectUrl) {
        // Redirection côté navigateur
        window.location.href = redirectUrl;
      } else {
        setError('Erreur : URL de redirection non définie.');
      }
    } catch (err) {
      console.error('Erreur lors de la demande de paiement :', err);
      setError('Erreur lors de la demande de paiement.');
    }
  };

  return (
    <div>
      <h2>Paiement Monetico</h2>
      {error && <p style={{ color: 'red' }}>{error}</p>}
      <form onSubmit={handleSubmit}>
        <div>
          <label>
            Montant (en centimes) :
            <input
              type="text"
              name="montant"
              value={montant}
              onChange={(e) => setMontant(e.target.value)}
              /*required*/
            />
          </label>
        </div>
        <div>
          <label>
            Référence :
            <input
              type="text"
              name="reference"
              value={reference}
              onChange={(e) => setReference(e.target.value)}
              /*required*/
            />
          </label>
        </div>
        <button type="submit">Payer avec Monetico</button>
      </form>
    </div>
  );
};

export default TestMoneticoPayment;
