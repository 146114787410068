import React, { useState } from 'react';
import { Formik, Field, Form  } from "formik";
import { Link, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import ProgressBar from 'react-bootstrap/ProgressBar';
import ImageUploading from 'react-images-uploading';
import BannerHeader from '../images/register/BANDEAU_INSCRIPTION.png';
import IconTransporteur from '../images/register/INSCRIPTION_METTRE_CAMION_A_LOUER.png';
import IconBetonnier from '../images/register/INSCRIPTION_CHERCHE_CAMION_A_LOUER.png';
import IconWarning from '../images/register/PICTO_ATTENTION.svg';
import { Col, Container, Row } from 'react-bootstrap';
import Autocomplete from '../components/Autocomplete';
import AddFile from '../images/add-file.png';
import AddPicture from '../images/add-picture.png';
import axios from 'axios';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { FilePond, registerPlugin } from 'react-filepond';
import 'filepond/dist/filepond.min.css';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import MetaTags from 'react-meta-tags';

const SignupSchema = Yup.object().shape({
  firstName: Yup.string().required("Le prénom est requis."),
  lastName: Yup.string().required("Le nom est requis."),
  password: Yup.string()
    .required('Veuillez saisir un mot de passe.')
    .min(6, 'Le mot de passe doit contenir au moins 6 caractères.'),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref('password'), null], 'Les mots de passe ne correspondent pas.')
    .required('Veuillez confirmer votre mot de passe.'),
    mail: Yup.string()
    .email("Email invalide")
    .required("L'email est requis."),
  phone: Yup.string().required("Le téléphone est requis.").min(10, "Le téléphone doit comporter au moins 10 chiffres."),
});

function RegisterPage() {
  const navigate = useNavigate();
  const [step, setStep] = useState(1);
  const [emailExist, setEmailExist] = useState('');
  const [totalStep, setTotalStep] = useState(0);
  const [type, setType] = useState("");
  const [nbVehicles, setNbVehicles] = useState(0);
  const [nbEmployees, setNbEmployees] = useState(0);
  const [offer, setOffer] = useState('Basic');
  const [subscription, setSubscription] = useState('user');
  const [showPassword, setShowPassword] = useState(false);

  const handleEditNbEmployees = (event) => {
    setNbEmployees(event.target.value);
  };


  function handleEditNbVehicle(event) {
    setNbVehicles(event.target.value)

    if (event.target.value <= 1) {
      setOffer('Pack 15 annonces')
      setSubscription('pack15')
    } else if (event.target.value > 1 && event.target.value <= 3) {
      setOffer('Pack 30 annonces')
      setSubscription('pack30')
    } else if (event.target.value > 3  && event.target.value <= 10) {
      setOffer('Abonnement Sérénité 12 mois')
      setSubscription('serenite12')
    } else if (event.target.value > 10) {
      setOffer('Abonnement Sérénité 24 mois')
      setSubscription('serenite24')
    } else if (event.target.value > 20) {
      setOffer ('Pack 10 annonces')
      setSubscription('pack10')
    } else if (event.target.value > 30) {
      setOffer ('Abonnement "régional"')
      setSubscription('betonierpro')
    } else if (event.target.value > 50) {
      setOffer ('Abonnement "national"')
      setSubscription('betonierproplus')
    }
  }

  function handleSelectType(profile) {
    setType(profile);

    if (profile === "betonnier") {
      setTotalStep(6);
    } else if (profile === "transporteur") {
      setTotalStep(6);
    }
  }

  const [images, setImages] = useState([]);
  const maxNumber = 69;
  const onChange = (imageList, addUpdateIndex) => {
      setImages(imageList);
  };

  const [kbis, setKbis] = useState([]);
  const maxNumberKbis = 69;
  const onChangeKbis = (kbisList, addUpdateIndex) => {
    setKbis(kbisList);
  };

  const [pdfFiles, setPdfFiles] = useState([]);

  const onChangePdf = (fileItems) => {
    setPdfFiles(fileItems.map(fileItem => fileItem.file));
  };

  const handleSubmit = (values) => {
    values.type = type;
    values.coordinates = document.getElementsByName("cities")[0].getAttribute('coordinates');
  
    // Vérifiez si les cases ubiwan et audit sont cochées
    values.ubiwan = values.ubiwan ? 1 : 0;
    values.spartesAudit = values.spartesAudit ? 1 : 0; 
  
    let data = new FormData();
  
    const images_to_send = new Array();
    images.map((image) => (
      images_to_send.push(image.file ? image.file : image)
    ));
  
    const kbis_to_send = new Array();
    pdfFiles.map((kbis) => (
      kbis_to_send.push(kbis.file ? kbis.file : kbis)
    ));
  
  
    Object.keys(values).map(function (key, index) {
        data.append(key, values[key])
    });
  
    data.append('images', images_to_send[0])
    data.append('kbis', kbis_to_send[0])
    
  
    data.append('subscription', subscription)
    data.append('nbVehicles', nbVehicles);
    data.append('nbEmployees', nbEmployees);
  
    let config = {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    }
  
    axios.post(process.env.REACT_APP_API_URL + "/v1/user/create", data, config)
    .then((response) => {
      if (response.status === 201) {

        /* MDWCHRIS : on va zapper l'etape 6 au profit d'une page dédié
        if (totalStep === 6) {
          setStep(6);
        } else {
          setStep(6);
        }
        */
  
        toast.success('Demande d\'inscription envoyée avec succès !', {
          position: "top-center",
          autoClose: 7000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });

        const userId = response.data.user_id;

        navigate("/selection-offre", { state: { userId } });

        // Envoi des données à Ubiwan si la case est cochée
        /* MDWCHRIS : pas besoin de déclencher une notification
        if (values.ubiwan) {
          axios.post(process.env.REACT_APP_API_URL + "/v1/ubiwan/notify", values)
          .then((response) => {
            console.log("Données envoyées à Ubiwan :", response);
          })
          .catch((error) => {
            console.log("Erreur lors de l'envoi des données à Ubiwan :", error);
          });
        }
  
        // Envoi des données à Spartes si la case est cochée
        if (values.spartesAudit && nbEmployees < 20 && nbVehicles < 20) {
          axios.post(process.env.REACT_APP_API_URL + "/v1/spartes/notify", values)
          .then((response) => {
            console.log("Données envoyées à Spartes :", response);
          })
          .catch((error) => {
            console.log("Erreur lors de l'envoi des données à Spartes :", error);
          });
        }
        */
  
      } else {
        toast.warning("Erreur lors de votre inscription.", {
          position: "top-center",
          autoClose: 7000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    })
    .catch((error) => {
      console.log("Erreur lors de l'inscription :", error);
      toast.error("Erreur lors de l'inscription. Veuillez réessayer.", {
        position: "top-center",
        autoClose: 7000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    });
  };

  return (
    <div className='register-form'>

      <MetaTags>
        <title>Louez et Gérer vos Véhicules pour le Béton |</title>
        <meta name="description" content="Inscrivez-vous sur SoluSpots pour accéder à un large choix de véhicules pour le béton. Rejoignez notre plateforme pour louer, gérer et optimiser vos opérations de chantier dès aujourd'hui."/>
      </MetaTags>

      <Link to='/'>
        <img src={BannerHeader} alt="SoluSpots - La solution en béton" />
      </Link>

      <div className='register-form-content'>
        <Formik
          initialValues={{
            firstName: "",
            lastName: "",
            companyName: "",
            mail: "",
            password: '',
            confirmPassword: '',
            images: {},
            phone: "",
            cities: {},
            siren: "",
            subscription: "",
            kbis: {},
            promocode: "",
            spartesAudit: false,
            ubiwan: false
          }}
          validationSchema={SignupSchema}
          onSubmit={handleSubmit}

        >
          {({ values, isValid, touched, errors }) => (
            <Form>
              <div className='register-form-content-items' style={{ display: step === 1 ? "block" : "none" }}>
                <Row>
                  <Col md={1} lg={3}></Col>
                  <Col md={10} lg={6}>
                    <div>
                      <h2>Inscription</h2>
                      <p>Créez votre profil et accédez à notre communauté dédiee au transport de<br />béton.</p>

                      <h3 className='register-form-content-text'>Parlez-nous de vous !</h3>

                      <div className='register-form-content-profile'>
                        <div onClick={() => handleSelectType('transporteur')} className={`register-form-content-block type-block ${type === "transporteur" ? "selected" : ""}`}>
                        <span className='type-block-text'>À partir de 3,99€ HT/jour</span>
                          <img className='type-block-image' src={IconTransporteur} />
                          <p className='type-block-title green'>Je suis transporteur</p>
                          <p className='type-block-title'>Je mets mon véhicule<br />en location</p>
                        </div>
                        <div onClick={() => handleSelectType('betonnier')} className={`register-form-content-block type-block ${type === "betonnier" ? "selected" : ""}`}>
                          <span className='type-block-text-free'>Gratuit</span>
                          <img className='type-block-image' src={IconBetonnier} />
                          <p className='type-block-title green'>Je suis bétonnier</p>
                          <p className='type-block-title'>Je cherche un véhicule<br />pour le béton</p>
                        </div>
                      </div>
                    </div>
                  </Col>
                  <Col md={1} lg={3}></Col>
                </Row>

                <div className='register-footer'>
                  <div className="register-progress-container hide-tablet hide-mobile">
                    {totalStep == 5 ?
                    <ul>
                      <li className='three-steps stepwithtruck'></li>
                      <li className='three-steps'></li>
                      <li className='three-steps'></li>
                      <li className='three-steps'></li>
                      <li className='three-steps'></li>
                    </ul>
                    :
                      <ul>
                        <li className='five-steps stepwithtruck'></li>
                        <li className='five-steps'></li>
                        <li className='five-steps'></li>
                        <li className='five-steps'></li>
                        <li className='five-steps'></li>
                        <li className='five-steps'></li>
                      </ul>
                    }

                    {totalStep == 5 ?
                      <ProgressBar className='register-progress-barre' now={(step / 6) * 100} />
                    :
                      <ProgressBar className='register-progress-barre' now={(step / totalStep) * 100} />
                    }
                  </div>

                  <div className="register-progress-mobile hide-desktop">
                    {totalStep == 5 ?
                      <ul>
                        <li className='three-steps active'></li>
                        <li className='three-steps'></li>
                        <li className='three-steps'></li>
                        <li className='three-steps'></li>
                        <li className='three-steps'></li>
                      </ul>
                      :
                        <ul>
                          <li className='five-steps active'></li>
                          <li className='five-steps'></li>
                          <li className='five-steps'></li>
                          <li className='five-steps'></li>
                          <li className='five-steps'></li>
                          <li className='five-steps'></li>
                        </ul>
                      }
                  </div>

                  <div className='register-button'>
                    <button className='register-button-back' type="button" onClick={() => navigate('/')}>Retour</button>
                    <button className='register-button-next' type="button"
                    onClick={() => { setStep(step + 1); }}
                    disabled={type === ""}>
                      Suivant
                    </button>
                  </div>
                </div>
              </div>

              <div className='register-form-content-items' style={{ display: step === 2 ? "block" : "none" }}>
                <Row>
                  <Col md={1} lg={3}></Col>
                  <Col md={10} lg={6}>
                    <div>
                      <h2>Complétez votre profil</h2>
                      {type === "transporteur" && ( 
                        <div className='register-form-content-items-warningblock'>
                          <img src={IconWarning} />
                          <p>Pensez à vous munir du document suivant avant de commencer votre inscription : Kbis.</p>
                        </div>
                      )}
                      <Row className='gy-2'>
                        <Col md={6}>
                          <Field name="lastName">
                            {({ field, form }) => (
                              <div className='register-form-field'>
                                <label>Nom *</label>
                                <input type="text" {...field} />
                                {touched.lastName && errors.lastName && <div className='error'>{errors.lastName}</div>}
                              </div>
                            )}
                          </Field>
                        </Col>
                        <Col md={6}>
                          <Field name="firstName">
                            {({ field, form }) => (
                              <div className='register-form-field'>
                                <label>Prénom *</label>
                                <input type="text" {...field} />
                                {touched.firstName && errors.firstName && <div className='error'>{errors.firstName}</div>}
                              </div>
                            )}
                          </Field>
                        </Col>
                        <Col md={6}>
                          <Field name="password">
                            {({ field, form }) => (
                              <>
                                <div className='register-form-field'>
                                  <label>Mot de passe *</label>
                                  <div className='register-form-field-password'>
                                    <input type={showPassword ? 'text' : 'password'} {...field} />
                                    <button className='button-hide-password' type="button" onClick={() => setShowPassword(!showPassword)}>
                                      {showPassword ? <FontAwesomeIcon icon={faEyeSlash} /> : <FontAwesomeIcon icon={faEye} />}
                                    </button>
                                  </div>
                                  {touched.password && errors.password && <div className='error'>{errors.password}</div>}
                                </div>
                              </>
                            )}
                          </Field>
                        </Col>
                        <Col md={6}>
                          <Field name="confirmPassword">
                            {({ field, form }) => (
                              <div className='register-form-field'>
                                <label>Confirmation de mot de passe *</label>
                                <div className='register-form-field-password'>
                                  <input type={showPassword ? 'text' : 'password'}{...field} />
                                  <button className='button-hide-password' type="button" onClick={() => setShowPassword(!showPassword)}>
                                    {showPassword ? <FontAwesomeIcon icon={faEyeSlash} /> : <FontAwesomeIcon icon={faEye} />}
                                  </button>
                                </div>
                                {touched.confirmPassword && errors.confirmPassword && <div className='error'>{errors.confirmPassword}</div>}
                              </div>
                            )}
                          </Field>
                        </Col>

                        {type == "betonnier" ?
                          <>
                            <Col md={6}>
                              <Field name="companyName">
                                {({ field, form }) => (
                                  <div className='register-form-field'>
                                    <label>Nom de l'entreprise</label>
                                    <input type="text" {...field} />
                                    {touched.companyName && errors.companyName && <div className='error'>{errors.companyName}</div>}
                                  </div>
                                )}
                              </Field>
                            </Col>
                            <Col md={6}>
                            </Col>
                          </>
                          : ''}

                        <Col md={6}>
                          <Field name="mail">
                            {({ field, form }) => (
                              <div className='register-form-field'>
                                <label>Mail *</label>
                                <input type="email" {...field} />
                                {touched.mail && errors.mail && <div className='error'>{errors.mail}</div>}
                                {emailExist == true && <div className='error'>Un compte existe déjà avec cet e-mail. <a href="/connexion">Connectez-vous.</a></div>}
                              </div>
                            )}
                          </Field>
                        </Col>
                        <Col md={6}>
                          <Field name="phone">
                            {({ field, form }) => (
                              <div className='register-form-field'>
                                <label>Téléphone *</label>
                                <input type="text" {...field} />
                                {touched.phone && errors.phone && <div className='error'>{errors.phone}</div>}
                              </div>
                            )}
                          </Field>
                        </Col>
                        <Col>
                          <Field name="images">
                            {({ form, field }) => (
                              <div className='register-form-field'>
                                <label htmlFor="images">Ajouter une photo de profil</label>
                                <ImageUploading
                                  multiple
                                  value={images}
                                  onChange={onChange}
                                  maxNumber={maxNumber}
                                  dataURLKey="data_url"
                                  className='location-form-upload'
                                >
                                    {({
                                        imageList,
                                        onImageUpload,
                                        onImageRemoveAll,
                                        onImageUpdate,
                                        onImageRemove,
                                        isDragging,
                                        dragProps
                                    }) => (
                                        // write your building UI
                                        <div className="register-form-field-image">
                                            {imageList.length > 0 ?
                                                ''
                                              :
                                                <>
                                                  <button
                                                      className='btnActionUpload'
                                                      type="button" style={isDragging ? { color: "red" } : null}
                                                      onClick={onImageUpload}
                                                      {...dragProps}
                                                      >
                                                      <img src={AddPicture} alt="" width="50" />
                                                  </button>
                                                  <p>Cela peut-être le logo de votre entreprise.</p>
                                                </>
                                              }
                                            {imageList.map((image, index) => (
                                                <div key={index} className="image-item">
                                                    <img src={image.data_url ? image.data_url : process.env.REACT_APP_API_URL + image} alt="" />
                                                    <div className="image-item__btn-wrapper">
                                                        <button className='btn btn-outline-secondary btn-sm' type="button" onClick={() => onImageUpdate(index)}>Modifier</button>
                                                        <button className='btn btn-outline-danger btn-sm' type="button" onClick={() => onImageRemove(index)}>Supprimer</button>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    )}
                                </ImageUploading>
                              </div>
                            )}
                          </Field>
                        </Col>
                      </Row>
                    </div>
                  </Col>
                  <Col md={1} lg={3}></Col>
                </Row>

                <div className='register-footer'>
                  <div className="register-progress-container hide-tablet hide-mobile">
                    {totalStep == 5 ?
                      <ul>
                        <li className='three-steps'></li>
                        <li className='three-steps stepwithtruck'></li>
                        <li className='three-steps'></li>
                        <li className='three-steps'></li>
                        <li className='three-steps'></li>
                      </ul>
                      :
                        <ul>
                          <li className='five-steps'></li>
                          <li className='five-steps stepwithtruck'></li>
                          <li className='five-steps'></li>
                          <li className='five-steps'></li>
                          <li className='five-steps'></li>
                          <li className='five-steps'></li>
                        </ul>
                      }

                      {totalStep == 5 ?
                        <ProgressBar className='register-progress-barre' now={(step / 5) * 100} />
                      :
                        <ProgressBar className='register-progress-barre' now={(step / totalStep) * 100} />
                      }
                    </div>

                    <div className="register-progress-mobile hide-desktop">
                      {totalStep == 5 ?
                        <ul>
                          <li className='three-steps'></li>
                          <li className='three-steps active'></li>
                          <li className='three-steps'></li>
                          <li className='three-steps'></li>
                          <li className='three-steps'></li>
                        </ul>
                        :
                          <ul>
                            <li className='five-steps'></li>
                            <li className='five-steps active'></li>
                            <li className='five-steps'></li>
                            <li className='five-steps'></li>
                            <li className='five-steps'></li>
                            <li className='five-steps'></li>
                          </ul>
                        }
                    </div>

                    <div className='register-button'>
                    <button className='register-button-back' type="button" onClick={() => {
                      setStep(step - 1);
                    }}>Retour</button>
                    <button className='register-button-next' type="button"
                      onClick={async (event) => {
                        try {
                          const response = await axios.post(process.env.REACT_APP_API_URL + "/v1/user/check-email", {
                            email: values.mail
                          });

                          if (response.data && response.data == 'exist') {
                            setEmailExist(true);
                          } else {
                            setEmailExist(false);

                            if (isValid && values.lastName !== '' && values.firstName !== '' && values.phone !== '' && values.mail !== '') {
                              setStep(step + 1);
                            }
                          }

                        } catch (error) {
                          // Gérer les erreurs de l'appel API
                          console.error('Erreur lors de l\'appel API :', error);
                        }
                      }}
                      disabled={values.lastName === '' || values.firstName === '' || values.phone === '' || values.mail === ''}
                    >Suivant</button>
                    </div>
                  </div>
                </div>

              <div className='register-form-content-items' style={{ display: step === 3 ? "block" : "none" }}>
                <Row>
                  <Col md={1} lg={3}></Col>
                  <Col md={10} lg={6}>
                    <div>
                      <h2>Complétez votre profil</h2>

                      <Row className='gy-2'>
                        <Col xs={12}>
                          <div className='register-form-field'>
                            <label>Localisation *</label>
                            <span>Où est située votre entreprise ?</span>
                            <Autocomplete name="cities" className={(errors.cities && touched.cities ? 'is-invalid' : '')} />
                          </div>
                        </Col>
                        <Col md={6}>
                          <Field name="siren">
                            {({ field, form }) => (
                              <div className='register-form-field'>
                                <label>Siren *</label>
                                <input type="text" name="siren" {...field} />
                              </div>
                            )}
                          </Field>
                        </Col>
                        <Col md={6}></Col>

                        {type === "transporteur" && (
                          <>
                            <Col xs={12}>
                              <Field name="kbis">
                                {({ form, field }) => (
                                  <div className='register-form-field'>
                                    <label htmlFor="kbis">Extrait de Kbis *</label>

                                    <FilePond
                                      files={pdfFiles}
                                      allowMultiple={true}
                                      onupdatefiles={onChangePdf}
                                      acceptedFileTypes={['application/pdf']}
                                      labelIdle={`<img src="${AddFile}" alt="" width="50" />`}
                                      className='btnFileUpload'
                                    />
                                  </div>
                                )}
                              </Field>
                            </Col>
                         
                            <Col xs={12}>
                              <div className='register-form-field register-form-field-nbvehicles'>
                                <label>Combien de véhicules avez-vous à louer ?</label>
                                <input type="number" min="0" name="nbVehicles" onChange={handleEditNbVehicle} />
                              </div>
                            </Col>

                            <Col xs={12}>
                              <div className='register-form-field-checkbox'>
                                <Field id="checkbox-agree-vehicles" type="checkbox" name="conformitecheckVehicles" />
                                <label htmlFor="checkbox-agree-vehicles">
                                  En continuant mon inscription, je certifie la conformité de mes véhicules<br />
                                  <a href="/charte" target="_blank">Lire la charte SoluSpots</a>
                                </label>
                              </div>
                            </Col>
                            </>
                          )}

                          <Col xs={12}>
                            <div className='register-form-field register-form-field-nbvehicles'>
                              <label>Combien de salariés avez-vous ?</label>
                              <input type="number" min="0" name="nbEmployees" onChange={handleEditNbEmployees} />
                            </div>
                          </Col>

                          <Col xs={12}>
                            <div className='register-form-field-checkbox'>
                              <Field id="checkbox-agree-employees" type="checkbox" name="conformitecheckEmployees" />
                              <label htmlFor="checkbox-agree-employees">
                                En continuant mon inscription, je certifie la conformité de mes salariés<br />
                                <a href="/charte" target="_blank">Lire la charte SoluSpots</a>
                              </label>
                            </div>
                          </Col>
                      </Row>
                    </div>
                  </Col>
                  <Col md={1} lg={3}></Col>
                </Row>

                <div className='register-footer'>
                  <div className="register-progress-container hide-tablet hide-mobile">
                    {totalStep == 5 ?
                      <ul>
                        <li className='three-steps'></li>
                        <li className='three-steps'></li>
                        <li className='three-steps stepwithtruck'></li>
                        <li className='three-steps'></li>
                        <li className='three-steps'></li>
                      </ul>
                      :
                      <ul>
                        <li className='five-steps'></li>
                        <li className='five-steps'></li>
                        <li className='five-steps stepwithtruck'></li>
                        <li className='five-steps'></li>
                        <li className='five-steps'></li>
                        <li className='five-steps'></li>
                      </ul>
                    }
                    {totalStep == 5 ?
                      <ProgressBar className='register-progress-barre' now={(step / 5) * 100} />
                      :
                      <ProgressBar className='register-progress-barre' now={(step / totalStep) * 100} />
                    }
                  </div>

                  <div className="register-progress-mobile hide-desktop">
                    {totalStep == 5 ?
                      <ul>
                        <li className='three-steps'></li>
                        <li className='three-steps'></li>
                        <li className='three-steps active'></li>
                        <li className='three-steps'></li>
                        <li className='three-steps'></li>
                      </ul>
                      :
                      <ul>
                        <li className='five-steps'></li>
                        <li className='five-steps'></li>
                        <li className='five-steps active'></li>
                        <li className='five-steps'></li>
                        <li className='five-steps'></li>
                        <li className='five-steps'></li>
                      </ul>
                    }
                  </div>

                  <div className='register-button'>
                    <button className='register-button-back' type="button" onClick={() => {
                      setStep(step - 1);
                    }}>Retour</button>
                    <button className='register-button-next' type="button" onClick={() => {
                      setStep(step + 1);
                    }} disabled={!isValid || values.cities == '' || values.siren == '' || (type === "transporteur" && (!values.conformitecheckVehicles || !values.conformitecheckEmployees))}>
                      Suivant
                    </button>
                  </div>
                </div>
              </div>

              <div className='register-form-content-items' style={{ display: step === 4 && totalStep != 5 ? "block" : "none" }}>
                <Container>
                  <Row>
                    <Col md={{ span: 8, offset: 2 }} lg={{ span: 6, offset: 3 }}>
                      <h2>Dernière étape</h2>
                      <p>Merci de compléter les informations suivantes pour finaliser votre inscription.</p>

                      <Row className="mt-5" style={{ alignContent: "stretch" }}>
                        <Col sm={12} className="mb-4">
                          <Field name="promocode">
                            {({ field, form }) => (
                              <div className='register-form-field notcentered'>
                                <label>Code promotionnel</label>
                                <input type="text" name="promocode" {...field} />
                                <p><small>Nous vous invitons à saisir un code promotionnel</small></p>
                              </div>
                            )}
                          </Field>
                        </Col>
                        <Col sm={12} className="mb-4">
                          <div className='register-form-field-checkbox'>
                            <Field id="checkbox-ubiwan" type="checkbox" name="ubiwan" />
                            <label htmlFor="checkbox-ubiwan">
                              Souhaitez-vous bénéficier de l’offre FLEET Ubiwan pour la gestion de votre flotte de véhicules ? Cochez cette case pour en profiter.
                            </label>
                          </div>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Container>                             
                <div className='register-footer'>
                  <div className="register-progress-container hide-tablet hide-mobile">
                    {totalStep == 5 ? (
                      <ul>
                        <li className='three-steps'></li>
                        <li className='three-steps'></li>
                        <li className='three-steps stepwithtruck'></li>
                        <li className='three-steps'></li>
                        <li className='three-steps'></li>
                      </ul>
                    ) : (
                      <ul>
                        <li className='five-steps'></li>
                        <li className='five-steps'></li>
                        <li className='five-steps'></li>
                        <li className='five-steps stepwithtruck'></li>
                        <li className='five-steps'></li>
                        <li className='five-steps'></li>
                      </ul>
                    )}
                    <ProgressBar className='register-progress-barre' now={(step / totalStep) * 100} />
                  </div>

                  <div className="register-progress-mobile hide-desktop">
                    {totalStep == 5 ? (
                      <ul>
                        <li className='three-steps'></li>
                        <li className='three-steps'></li>
                        <li className='three-steps active'></li>
                        <li className='three-steps'></li>
                        <li className='three-steps'></li>
                      </ul>
                    ) : (
                      <ul>
                        <li className='five-steps'></li>
                        <li className='five-steps'></li>
                        <li className='five-steps'></li>
                        <li className='five-steps active'></li>
                        <li className='five-steps'></li>
                        <li className='five-steps'></li>
                      </ul>
                    )}
                  </div>
                  <div className='register-button'>
                    <button className='register-button-back' type="button" onClick={() => {
                      setStep(step - 1);
                    }} disabled={!isValid}>Retour</button>
                    <button className='register-button-next' type="submit"
                      >Valider mon inscription</button>
                  </div>
                </div>
              </div>

              {totalStep == 6 ? (
                <div className='register-form-content-items' style={{ display: step === 6 ? "block" : "none" }}>
                  <Container>
                    <Row>
                      <Col md={12} lg={12}>
                        <h2>Abonnement</h2>
                        <p>SoluSpots vous propose plusieurs offres. Pour {nbVehicles} véhicules à louer, l'offre {offer} est la plus adaptée pour vous. <br/> Celle-ci est modifiable à tout moment dans votre espace personnel.</p>
                        <Row className="mt-5" style={{ alignContent: "stretch" }}>
                          {type === "transporteur" && (
                            <>
                              <Col sm={12} md={6} xl={4} className="mb-4">
                                <div className="register-price-cards">
                                  <h2 className="register-price-cards-title">Packs d'annonces</h2>
                                  <hr className="register-price-cards-separator" />
                                  <p className="register-price-cards-number">225€ - 300€</p>
                                  <ul className="register-price-cards-list">
                                    <li className="register-price-cards-list-item">15 Spotmonnaies - 225€</li>
                                    <li className="register-price-cards-list-item">30 Spotmonnaies - 300€</li>
                                  </ul>
                                  <div className="register-price-cards-button">
                                    {subscription === 'pack15' ? (
                                      <>
                                        <Link to="#" className="register-button-promo-selected">Pack 15 annonces sélectionné</Link>
                                        <button className="register-button-credit" type="button" onClick={() => window.location.href = 'URL_DE_LA_PAGE_DE_PAIEMENT_VISA'}>
                                          Créditer dès maintenant mon compte
                                        </button>
                                      </>
                                    ) : subscription === 'pack30' ? (
                                      <>
                                        <Link to="#" className="register-button-promo-selected">Pack 30 annonces sélectionné</Link>
                                        <button className="register-button-credit" type="button" onClick={() => window.location.href = 'URL_DE_LA_PAGE_DE_PAIEMENT_VISA'}>
                                          Créditer dès maintenant mon compte
                                        </button>
                                      </>
                                    ) : (
                                      <>
                                        <button onClick={() => setSubscription('pack15')} className="register-button-promo">Je choisis le pack 15 annonces</button>
                                        <button onClick={() => setSubscription('pack30')} className="register-button-promo">Je choisis le pack 30 annonces</button>
                                      </>
                                    )}
                                  </div>
                                </div>
                              </Col>

                              <Col sm={12} md={6} xl={4} className="mb-4">
                                <div className="register-price-cards">
                                  <h2 className="register-price-cards-title">Abonnement Sérénité 12 mois</h2>
                                  <hr className="register-price-cards-separator" />
                                  <p className="register-price-cards-number">249,99€/mois</p>
                                  <ul className="register-price-cards-list">
                                    <li className="register-price-cards-list-item">12 mois - 249,99€/mois avec engagement </li>
                                  </ul>
                                  <div className="register-price-cards-button">
                                    {subscription === 'serenite12' ? (
                                      <Link to="#" className="register-button-promo-selected">Offre sélectionnée</Link>
                                    ) : (
                                      <button onClick={() => setSubscription('serenite12')} className="register-button-promo">Je choisis l'abonnement 12 mois</button>
                                    )}
                                  </div>
                                </div>
                              </Col>

                              <Col sm={12} md={6} xl={4} className="mb-4">
                                <div className="register-price-cards">
                                  <h2 className="register-price-cards-title">Abonnement Sérénité 24 mois</h2>
                                  <hr className="register-price-cards-separator" />
                                  <p className="register-price-cards-number">199,99€/mois</p>
                                  <ul className="register-price-cards-list">
                                    <li className="register-price-cards-list-item">24 mois - 199,99€/mois avec engagement </li>
                                  </ul>
                                  <div className="register-price-cards-button">
                                    {subscription === 'serenite24' ? (
                                      <Link to="#" className="register-button-promo-selected">Offre sélectionnée</Link>
                                    ) : (
                                      <button onClick={() => setSubscription('serenite24')} className="register-button-promo">Je choisis l'abonnement 24 mois</button>
                                    )}
                                  </div>
                                </div>
                              </Col>
                            </>
                          )}

                          {type === "betonnier" && (
                            <>
                              <Col sm={12} md={6} xl={4} className="mb-4">
                                <div className="register-price-cards">
                                  <h2 className="register-price-cards-title">Pack 10 annonces</h2>
                                  <hr className="register-price-cards-separator" />
                                  <p className="register-price-cards-number">150€</p>
                                  <ul className="register-price-cards-list">
                                    <li className="register-price-cards-list-item">10 Spotmonnaies - 150€</li>
                                  </ul>
                                  <div className="register-price-cards-button">
                                    {subscription === 'pack10' ? (
                                      <>
                                        <Link to="#" className="register-button-promo-selected">Pack 10 annonces sélectionné</Link>
                                        <button className="register-button-credit" type="button" onClick={() => window.location.href = 'URL_DE_LA_PAGE_DE_PAIEMENT_VISA'}>
                                          Créditer dès maintenant mon compte
                                        </button>
                                      </>
                                      ) : (
                                        <button onClick={() => setSubscription('pack10')} className="register-button-promo">Offre sélectionnée</button>
                                    )}
                                  </div>
                                </div>
                              </Col>

                              <Col sm={12} md={6} xl={4} className="mb-4">
                                <div className="register-price-cards">
                                  <h2 className="register-price-cards-title">Abonnement "régional"</h2>
                                  <hr className="register-price-cards-separator" />
                                  <p className="register-price-cards-number">249,99€/mois avec engagement </p>
                                  <ul className="register-price-cards-list">
                                    <li className="register-price-cards-list-item">Audit gratuit des coûts sociaux et fiscaux</li>
                                  </ul>
                                  <div className="register-price-cards-button">
                                    {subscription === 'betonnierPlus' ? (
                                      <>
                                        <Link to="#" className="register-button-promo-selected">Offre sélectionnée</Link>
                                        <div className='register-form-field-checkbox mt-2'>
                                          <Field id="checkbox-spartesAudit-betonnierPlus" type="checkbox" name="spartesAudit" />
                                          <label htmlFor="checkbox-spartesAudit-betonnierPlus">
                                            Souhaitez-vous bénéficier d’un audit gratuit des coûts sociaux et fiscaux avec Spartes ? Cochez cette case pour en profiter.
                                          </label>
                                        </div>
                                      </>
                                    ) : (
                                      <Link onClick={() => setSubscription('betonnierPlus')} className="register-button-promo">Je choisis cette offre</Link>
                                    )}
                                  </div>
                                </div>
                              </Col>

                              <Col sm={12} md={6} xl={4} className="mb-4">
                                <div className="register-price-cards">
                                  <h2 className="register-price-cards-title">Abonnement "national" </h2>
                                  <hr className="register-price-cards-separator" />
                                  <p className="register-price-cards-number">499,99€/mois avec engagement </p>
                                  <ul className="register-price-cards-list">
                                    <li className="register-price-cards-list-item">Audit gratuit des coûts sociaux et fiscaux</li>
                                  </ul>
                                  <div className="register-price-cards-button">
                                    {subscription === 'betonnierPro' ? (
                                      <>
                                        <Link to="#" className="register-button-promo-selected">Offre sélectionnée</Link>
                                        <div className='register-form-field-checkbox mt-2'>
                                          <Field id="checkbox-spartesAudit-betonnierPro" type="checkbox" name="spartesAudit" />
                                          <label htmlFor="checkbox-spartesAudit-betonnierPro">
                                            Souhaitez-vous bénéficier d’un audit gratuit des coûts sociaux et fiscaux avec Spartes ? Cochez cette case
                                            pour en profiter.
                                          </label>
                                        </div>
                                      </>
                                      ) : (
                                        <Link onClick={() => setSubscription('betonnierPro')} className="register-button-promo">Je choisis cette offre</Link>
                                    )}
                                  </div>
                                </div>
                              </Col>
                            </>
                          )}
                        </Row>
                      </Col>
                    </Row>
                    <Col sm={12} className="mb-4">
                      <div className='register-form-field-checkbox'>
                        <Field id="checkbox-conformitecgv" type="checkbox" name="conformitecgv" />
                        <label htmlFor="checkbox-conformitecgv">
                          Je confirme avoir pris connaissance des <a href="/conditions-generales-de-vente" target="_blank">Conditions Générales de Vente</a>.
                        </label>
                      </div>
                    </Col>
                    <div className='register-footer'>
                      <div className="register-progress-container hide-tablet hide-mobile">
                        {totalStep == 5 ? (
                          <ul>
                            <li className='three-steps'></li>
                            <li className='three-steps'></li>
                            <li className='three-steps'></li>
                            <li className='three-steps'></li>
                            <li className='three-steps stepwithtruck'></li>
                          </ul>
                        ) : (
                          <ul>
                            <li className='five-steps'></li>
                            <li className='five-steps'></li>
                            <li className='five-steps'></li>
                            <li className='five-steps'></li>
                            <li className='five-steps'></li>
                            <li className='five-steps stepwithtruck'></li>
                          </ul>
                        )}
                        <ProgressBar className='register-progress-barre' now={(step / totalStep) * 100} />
                      </div>

                      <div className="register-progress-mobile hide-desktop">
                        {totalStep == 5 ? (
                          <ul>
                            <li className='three-steps'></li>
                            <li className='three-steps'></li>
                            <li className='three-steps active'></li>
                            <li className='three-steps'></li>
                            <li className='three-steps'></li>
                          </ul>
                        ) : (
                          <ul>
                            <li className='five-steps'></li>
                            <li className='five-steps'></li>
                            <li className='five-steps'></li>
                            <li className='five-steps active'></li>
                            <li className='five-steps'></li>
                            <li className='five-steps'></li>
                          </ul>
                        )}
                      </div>

                      <div className='register-button'>
                        <button className='register-button-next' type="button"
                          onClick={() => {
                            if (document.getElementById('checkbox-conformitecgv') && document.getElementById('checkbox-conformitecgv').checked) {
                              // Valider l'abonnement et rediriger vers la page de connexion
                              setSubscription(offer);
                              navigate('/connexion');
                            } else {
                              // Passer cette étape et rediriger vers la page de connexion
                              setStep(step + 1);
                              navigate('/connexion');
                            }
                          }}>
                          {document.getElementById('checkbox-conformitecgv') && document.getElementById('checkbox-conformitecgv').checked ? 'Valider l\'abonnement' : 'Passer cette étape'}
                        </button>
                      </div>
                    </div>
                  </Container>
                </div>
              ) : null}  
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
}

export default RegisterPage;