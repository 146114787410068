import React, { useEffect, useRef } from 'react';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import axios from 'axios';
import { useNavigate } from "react-router-dom";
import 'leaflet/dist/leaflet.css';

import TruckForm from './TruckForm';

function LocationForm(props) {

    return (
        <div className="create-product">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <h2>Mes camions - Ajouter un camion</h2>

                        <TruckForm
                            initialValues={{
                                name: '',
                                type: '',
                                capacity: '',
                                fuel: '',
                                location: '',
                                registration: '',
                                carteGrise: false,
                                availability: '',
                                autoRenew: false,
                                price: '',
                                images: {},
                                docs: {},
                                cities: {},
                                dates: {},
                                enabled: ''
                            }}
                            validationSchema={Yup.object().shape({
                                name: Yup.string()
                                    .required('Le nom du camion est requis.'),
                                type: Yup.string()
                                    .required('Le type de camion est requis.'),
                                capacity: Yup.number()
                                    .required('La capacité de charge est requise.'),
                                fuel: Yup.string()
                                    .required('Le carburant est requis.'),
                                location: Yup.string()
                                    .required('La localisation est requise.'),
                                registration: Yup.string()
                                    .required('La plaque d\'immatriculation est requise.'),
                                carteGrise: Yup.boolean()
                                    .required('La carte grise est requise.'),
                                availability: Yup.string()
                                    .required('La disponibilité est requise.'),
                                autoRenew: Yup.boolean(),
                                price: Yup.number()
                                    .required('Le prix est requis.'),
                            })}
                            props={props}
                            isCreate={true}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default LocationForm;
