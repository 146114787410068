import React from 'react';
import Banner from "../components/Banner";
import { Col, Container, Row } from "react-bootstrap";
import MetaTags from 'react-meta-tags';
import { Capacitor } from '@capacitor/core';
import Ouvrier from "../images/discover/worker-1-about-page.png"; 
import Nicolas from "../images/discover/Photo-Nicolas.png";
import IconeMission from "../images/discover/mission-icon.png";
import IllustrationMiseEnContactV2 from "../images/discover/illustration-mise-en-contact-v2.png";
import IconeValeurs from "../images/discover/valeurs-icon.png";
import VisuelContact from "../images/discover/SOLUSPOTS_TRANSPORTEUR_MESSAGERIE.png";
import FiabiliteV2 from "../images/discover/fiabilite-v2.png";
import NotreApprocheImage from "../images/discover/worker-notre-approche-v3.png";
import VisuelRechercheAnnonce from "../images/discover/Phone-mockup-inscription-appli.png";
import GuillemetsOuverts from "../images/discover/guillemets-open.png";
import Ligne from "../images/discover/Mask_group.png";

const AboutPage = () => {
    const titleBanner = "Créateur d'une <span class='banner-title-colored'>application performante</span> pour répondre aux besoins du béton et du transport.";

    return (
        <div className="aboutpage">
            <MetaTags>
                <title>À propos | SoluSpots</title>
                <meta name="description" content="En savoir plus sur SoluSpots, la plateforme qui simplifie la logistique de vos chantiers."/>
            </MetaTags>
            <Banner titleBanner={titleBanner} bigBanner={true}></Banner>

            <div className="aboutpage-mediumcontainer">
                <div className="aboutpage-introduction-section">
                    <div className="aboutpage-introduction-section-item">
                        <div className="aboutpage-introduction-section-item-content">
                            <h2 className="aboutpage-introduction-section-item-title">
                            <span className="aboutpage-introduction-section-item-title-colored">Une solution</span> créée par un <br/> professionnel, pour les <br/> professionnels du béton.
                            </h2>
                            <p className='aboutpage-introduction-section-item-text'>
                            Nicolas Guesnet, transporteur de béton, a fondé <br/> Soluspots pour répondre à l'absence de solutions<br/>logistiques adaptées. Fort de son expertise, il a créé<br/> une plateforme sur-mesure, pensée pour le quotidien<br/> des transporteurs et bétonniers.
                            </p>
                            <p className='aboutpage-introduction-section-item-text-secondary'>
                            <strong>Aujourd’hui, Soluspots reflète cette vision :</strong> offrir aux professionnels du béton une<br/>solution efficace, accessible et innovante pour la livraison de béton.
                            </p>
                        </div>
                        <div className="aboutpage-introduction-section-item-image">
                            <img src={Ouvrier} alt="Description de l'image" />
                        </div>
                    </div>
                </div>
            </div>

            <div className="aboutpage-bigcontainer">
                <div className="aboutpage-history-section">
                    <Container>
                        <Row>
                            <h2>L’histoire derrière <span className="aboutpage-history-section-presentation-title-colored"> SoluSpots</span></h2>
                            <div className="aboutpage-history-section-presentation">
                                <img src={Nicolas} alt="Image gauche" className="aboutpage-history-section-image" />
                                <div className="aboutpage-history-section-bubble">
                                    <div className='aboutpage-history-section-bubble-content'>
                                        <img  src={GuillemetsOuverts} alt="Guillemets ouverts" className="aboutpage-history-section-text-icon" />
                                        <h3 className="aboutpage-history-section-text">
                                            “À la tête d’une entreprise de transport, j'ai constaté un problème récurrent : alors que les bétonniers peinent à trouver des camions disponibles, de mon côté, mes propres camions restent parfois inutilisés.
                                            <br/>
                                            <span className="aboutpage-history-section-text-highlight">
                                                Une opportunité évidente, mais aucune solution adaptée n’existait...
                                            </span>”
                                        </h3>
                                        <p>
                                            C’est ainsi qu’est <strong>née l'idée de SoluSpots</strong>, une <strong>application simple et efficace pour connecter bétonniers et transporteurs.</strong> Les  premiers trouvent des camions en quelques clics, les seconds optimisent leur flotte et rentabilisent leur investissement.
                                        </p>
                                        <p>
                                            Et maintenant ? <strong>SoluSpots</strong>, c’est bien plus qu’une plateforme : c’est un réseau qui <strong>fluidifie le transport de béton et maximise les ressources.</strong> Une révolution pour un secteur qui en avait besoin !
                                        </p>
                                        <p className="aboutpage-history-author">
                                            <strong className="aboutpage-history-author-name">Nicolas Guesnet</strong><br/>
                                            <span className="aboutpage-history-author-title">Fondateur de SoluSpots</span>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </Row>
                    </Container>
                    
                </div>
                <div className="mission-section">
                    <Container>
                        <img src={IconeMission} alt="Mission Icon" className="mission-icon" />
                        <h2 className="mission-title">Notre<span> mission</span></h2>
                        <Row className="mt-5 gx-5 justify-content-center">
                            <Col xs={12} md={10}>
                                <div className="mission-content">
                                    <img src={IllustrationMiseEnContactV2} alt="Image de la mission gauche" className="mission-image-left" />
                                    <div className="mission-text-content">
                                        <h2 className="mission-content-title">
                                            <span className='mission-highlight'>Connecter bétonniers et transporteurs</span> pour une logistique optimisée
                                        </h2>
                                        <p className="mission-description">
                                            Chez Soluspots.fr, notre mission est de connecter transporteurs et centrales à béton pour optimiser la logistique, réduire les coûts et garantir des livraisons rapides et efficaces.
                                        </p>

                                        {Capacitor.getPlatform() != 'ios' ? (
                                            <div className="button-wrapper">
                                            <   a className="solu-btn solu-btn-primary mt-4 mb-4" href="/inscription" bis_skin_checked="1">Je m'inscris</a>
                                            </div>
                                        ) : ''}
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
                                    
                <div className="values-section">
                    <Container>
                        <img src={IconeValeurs} alt="Values Icon" className="values-icon" />
                        <h2 className="values-title">Nos<span> valeurs</span></h2>
                        <Row className="mt-5 gx-5 g-4 justify-content-center values-section-row">
                            <Col xs={11} sm={{'span':8,'offset':0}} md={{'span':6,'offset':0}} lg={4}>
                                <div className="values-card">
                                    <span className="values-card-number">01</span>
                                    <img className="values-card-img" src={FiabiliteV2} />
                                    <p className="values-card-title">La fiabilité</p>
                                    <p className="values-card-subtitle">
                                        La fiabilité est essentielle pour une logistique optimale. Chaque transporteur et entreprise est vérifié afin d'assurer des collaborations sûres et efficaces à chaque étape.
                                    </p>
                                </div>
                            </Col>
                            <Col xs={11} sm={{'span':8,'offset':0}} md={{'span':6,'offset':0}} lg={4}>
                                <div className="values-card">
                                    <span className="values-card-number">02</span>
                                    <img className="values-card-img" src={VisuelContact} />
                                    <p className="values-card-title">L'expertise</p>
                                    <p className="values-card-subtitle">
                                        Nous misons sur une logistique de proximité pour réduire les coûts, les émissions de CO₂ et l'impact environnemental, tout en soutenant l’économie locale et la qualité du béton.
                                    </p>
                                </div>
                            </Col>
                            <Col xs={11} sm={{'span':8,'offset':0}} md={{'span':6,'offset':0}} lg={4}>
                                <div className="values-card">
                                    <span className="values-card-number">03</span>
                                    <img className="values-card-img" src={IllustrationMiseEnContactV2} />
                                    <p className="values-card-title">La proximité</p>
                                    <p className="values-card-subtitle">
                                        Nous avons conçu une application pratique pour répondre aux besoins des transporteurs et bétonniers. Notre plateforme optimise la performance tout en réduisant les contraintes opérationnelles.
                                    </p>
                                </div>
                            </Col>
                        </Row>
                        {Capacitor.getPlatform() != 'ios' ? (
                            <div className="values-button-container">
                                <a className="solu-btn solu-btn-primary mt-4" href="/inscription">Je partage ces valeurs</a>
                            </div>
                        ) : ''}
                    </Container>
                </div>
                <div className="approach-section">
                    <Container className="approach-container">
                        <Row className="approach-row mt-5 gx-5 align-items-center">
                            <Col md={6} className="approach-col-text">
                                <h2 className="approach-title">Notre<span> approche</span></h2>
                                <p className="approach-subtitle">Une méthode structurée et performante</p>
                                <p className="approach-description">Nous connectons bétonniers et transporteurs selon des critères de proximité, technicité et rapidité pour maximiser leur satisfaction</p>
                                <p className="approach-subdescription"><strong>Notre équipe veille à une expérience fluide et sans surprises.</strong></p>
                                {Capacitor.getPlatform() != 'ios' ?
                                    <a className="solu-btn solu-btn-primary mt-4 mb-4" href="/inscription" bis_skin_checked="1">J’intègre la communauté</a>
                                : ''}
                            </Col>
                            <Col md={6} className="approach-col-image">
                                <img src={NotreApprocheImage} alt="Notre approche" className="img-fluid approach-image" />
                            </Col>
                        </Row>
                    </Container>
                </div>
                <Container className="py-5">
                    <Row className="d-flex align-items-center justify-content-center">
                        <Col md={12} xl={6} className='d-flex justify-content-center order-1 order-xl-1 position-relative'>
                            <img src={VisuelRechercheAnnonce} alt="Camion loueurs - SoluSpot" className="camion-image" />
                            <img src={Ligne} alt="Line" className="line" />
                        </Col>
                        <Col md={12} xl={6} className='order-2 order-xl-2'>
                            <div className="discover-section-rental-content">
                                <h2 className="ready-to-improve-title">
                                    Prêt à <span className="discover-section-rental-content-title-colored">améliorer</span> votre logistique béton ?
                                </h2>
                                <p className="home-section-steps-text">Optimisez vos transports et projets de bétonnage<br/> avec Soluspots. Collaborez dès aujourd'hui avec des<br/> partenaires de confiance grâce à nos solutions<br/> adaptées !</p>
                                <p className="home-section-desc-content-block-text"><strong>Rejoignez Soluspots.fr pour transformer la logistique de votre entreprise !</strong></p>
                                <div className="button-container">
                                    <a className="solu-btn solu-btn-primary mt-4" href="/inscription" bis_skin_checked="1">je rejoins Soluspots.fr</a>
                                    <a className="solu-btn solu-btn-outline mt-4 aboutpage-btn-outline" href="/contact" bis_skin_checked="1">J’ai une question</a>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
    );
};

export default AboutPage;