import React, { useEffect, useState } from 'react';
import LogoHeader from '../images/logos/LOGO_SOLUSPOTS_HEADER.png';
import LogoHeaderMobile from '../images/logos/LOGO_SOLUSPOTS_HEADER_MOBILE.png';
import { Nav, NavDropdown, Navbar, Container } from 'react-bootstrap';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useAuth } from '../ReactApp';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF, faLinkedinIn, faYoutube } from '@fortawesome/free-brands-svg-icons';
import { faCircleUser } from '@fortawesome/free-solid-svg-icons';
import { faBell } from '@fortawesome/free-regular-svg-icons';
import axios from 'axios';
import btnClose from '../images/btn-close.jpg';
import { Capacitor } from '@capacitor/core';

const Menu = ({ activeItem, setActiveItem }) => {
    const { user } = useAuth();
    const location = useLocation();
    const currentUrl = location.pathname;
    const navigate = useNavigate();
    const [params, setParams] = useState({});
    const [nbMessages, setNbMessages] = useState(0);
    const [notifs, setNotifs] = useState([]);
    const [countNotifs, setCountNotifs] = useState(0);
    const [openSidebar, setOpenSidebar] = useState(false);
    const [loadUser, setLoadUser] = useState(true);

    const logOut = () => {
        window.localStorage.removeItem("soluspot-token");

        setLoadUser(null);

        if (location.pathname === "/") {
            window.location.reload();
        } else {
            navigate("/");
            window.location.reload();
        }
    };

    useEffect(() => {
        let intervalId = '';

        if (user) {
            setLoadUser(false);
            getNbMessages();
            getNotifs();

            intervalId = setInterval(() => {
                getNbMessages();
                getNotifs();
            }, 10000);
        }
        return () => clearInterval(intervalId);
    }, [user]);

    useEffect(() => {
        if (user) {
            setParams({
                'id_user': user._id,
            });

            getNbMessages();
            getNotifs();
        }
    }, [user]);

    const getNbMessages = async () => {
        try {
            const response = await axios.post(process.env.REACT_APP_API_URL + `/v1/nb-messages`, {'id_user': user._id});
            setNbMessages(response.data);
        } catch (error) {
            console.error(error);
        }
    };

    const getNotifs = async () => {
        try {
            const response = await axios.post(process.env.REACT_APP_API_URL + `/v1/get-notifs`, {'id_user': user._id});
            setNotifs(response.data.notifs);
            setCountNotifs(response.data.count_notifs);
        } catch (error) {
            console.error(error);
        }
    };

    const handleOpenSidebar = () => {
        setOpenSidebar(!openSidebar);
    };

    useEffect(() => {
        if (openSidebar) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'initial';
        }
    }, [openSidebar]);

    return (
        <>
            <Navbar collapseOnSelect className='app-navbar' expand="lg" sticky="top">
                <Container fluid className="app-navbar-secondary">
                    <div className="navbar-secondary">
                        <a href="https://www.facebook.com/profile.php?id=100092581599269" className='nav-link nav-link-social' target='blank'><FontAwesomeIcon icon={faFacebookF} /></a>
                        <a href="https://www.linkedin.com/company/soluspots/" className='nav-link nav-link-social' target='blank'><FontAwesomeIcon icon={faLinkedinIn} /></a>
                        <a href="https://www.youtube.com/@soluspots" className='nav-link nav-link-social' target='blank'><FontAwesomeIcon icon={faYoutube} /></a>
                    </div>
                    <div className="navbar-secondary">
                        {Capacitor.getPlatform() === 'ios' ?
                            <a href="/contact" className='nav-link nav-link-support'>Assistance</a>
                        :
                            (user ?
                                <a href="/contact" className='nav-link nav-link-support'>Assistance</a>
                            :
                                <a href="/inscription" className='nav-link nav-link-support'>Je m'inscris</a>
                            )
                        }
                    </div>
                </Container>
                <Container fluid>
                    <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                    <Navbar.Brand href="/">
                        <img className="hide-mobile hide-tablet" src={LogoHeader} alt="" />
                        <img className="hide-desktop" src={LogoHeaderMobile} alt="" />
                    </Navbar.Brand>
                    <Navbar.Collapse id="responsive-navbar-nav">
                        <Nav className="nav-menu">
                            {window.localStorage.getItem("soluspot-token") ? (
                                user ? (
                                    user.role === 'user' ? (
                                        <>
                                        <li className={`nav-item ${activeItem === 'Mes annonces' ? 'active' : ''} nav-link`}>
                                            <Link
                                            to="/profil"
                                            state={{ blockToDisplay: 'worksites' }}
                                            className="profile-menulight-item-button my-0"
                                            onClick={() => setActiveItem('Mes annonces')}
                                            >
                                            Mes annonces
                                            </Link>
                                        </li>
                                        <li className={`nav-item ${activeItem === 'Mes réservations' ? 'active' : ''} nav-link`}>
                                            <Link
                                            to="/profil"
                                            state={{ blockToDisplay: 'reservations' }}
                                            className="profile-menulight-item-button my-0"
                                            onClick={() => setActiveItem('Mes réservations')}
                                            >
                                            Mes réservations
                                            </Link>
                                        </li>
                                        <li className={`nav-item ${activeItem === 'Messagerie' ? 'active' : ''} nav-link`}>
                                            <Link
                                            to="/profil"
                                            state={{ blockToDisplay: 'messagerie' }}
                                            className="profile-menulight-item-button my-0"
                                            onClick={() => setActiveItem('Messagerie')}
                                            >
                                            Messagerie {nbMessages ? <span className='profile-menulight-item-button-nbmessages'>{nbMessages}</span> : ''}
                                            </Link>
                                        </li>
                                        </>
                                    ) : (
                                        <>
                                        <li className={`nav-item ${activeItem === 'Mes camions' ? 'active' : ''} nav-link`}>
                                            <Link
                                            to="/profil"
                                            state={{ blockToDisplay: 'products' }}
                                            className="profile-menulight-item-button my-0"
                                            onClick={() => setActiveItem('Mes camions')}
                                            >
                                            Mes camions
                                            </Link>
                                        </li>
                                        <li className={`nav-item ${activeItem === 'Mes réservations' ? 'active' : ''} nav-link`}>
                                            <Link
                                            to="/profil"
                                            state={{ blockToDisplay: 'reservations' }}
                                            className="profile-menulight-item-button my-0"
                                            onClick={() => setActiveItem('Mes réservations')}
                                            >
                                            Mes réservations
                                            </Link>
                                        </li>
                                        <li className={`nav-item ${activeItem === 'Messagerie' ? 'active' : ''} nav-link`}>
                                            <Link
                                            to="/profil"
                                            state={{ blockToDisplay: 'messagerie' }}
                                            className="profile-menulight-item-button my-0"
                                            onClick={() => setActiveItem('Messagerie')}
                                            >
                                            Messagerie {nbMessages ? <span className='profile-menulight-item-button-nbmessages'>{nbMessages}</span> : ''}
                                            </Link>
                                        </li>
                                        </>
                                    )
                                ) : (
                                <>
                                    <Nav.Link className={currentUrl === '/' ? "active" : ''} href="/">Accueil</Nav.Link>
                                    <Nav.Link className={currentUrl === '/transporteur' ? "active" : ''} href="/transporteur">Vous êtes transporteur</Nav.Link>
                                    <Nav.Link className={currentUrl === '/betonnier' ? "active" : ''} href="/betonnier">Vous êtes bétonnier</Nav.Link>
                                    <Nav.Link className={currentUrl === '/a-propos' ? "active" : ''} href="/a-propos">Découvrir SoluSpots</Nav.Link>
                                    <Nav.Link className={currentUrl === '/contact' || currentUrl === '/contact/' ? "active" : ''} href="/contact/">Contact</Nav.Link>
                                </>
                                )
                            ) : (
                                <>
                                <Nav.Link className={currentUrl === '/' ? "active" : ''} href="/">Accueil</Nav.Link>
                                <Nav.Link className={currentUrl === '/transporteur' ? "active" : ''} href="/transporteur">Vous êtes transporteur</Nav.Link>
                                <Nav.Link className={currentUrl === '/betonnier' ? "active" : ''} href="/betonnier">Vous êtes bétonnier</Nav.Link>
                                <Nav.Link className={currentUrl=== '/a-propos' ? "active" : ''} href="/a-propos">Découvrir SoluSpots</Nav.Link>
                                <Nav.Link className={currentUrl === '/contact' || currentUrl === '/contact/' ? "active" : ''} href="/contact/">Contact</Nav.Link>
                                </>
                            )
                        }

                        </Nav>
                    </Navbar.Collapse>
                    <div className='spacerMenuForMobile'></div>
                    {!loadUser ? (
                        <>
                            {/* <a href="/profil" className='nav-link-notif'><FontAwesomeIcon icon={faBell} /></a> */}
                            {countNotifs > 0 ?
                                <div className='nav-link-notif dropleft'>
                                    <NavDropdown title={<FontAwesomeIcon icon={faBell} className="dropleft" />} >
                                        {notifs.map((item, index) => (
                                            <NavDropdown.Item key={index} as={Link} to="/profil" state={{ blockToDisplay: 'messagerie' }}>Demande d'offre de location</NavDropdown.Item>
                                        ))}
                                    </NavDropdown>
                                    {countNotifs ? <span className='nav-link-notif-nbmessages'>{countNotifs}</span> : ''}
                                </div>
                            :
                                <div className='nav-link-notif'>
                                    <NavDropdown title={<FontAwesomeIcon icon={faBell} drop='down' alignright="true" />} >
                                        <span>Aucune notification</span>
                                    </NavDropdown>
                                </div>
                            }

                            <NavDropdown className='nav-link-search dropleft' title={'Je recherche'}>
                                <>
                                    <NavDropdown.Item as={Link} to="/resultat" onClick={() => setActiveItem('')}>Un camion</NavDropdown.Item>
                                    <NavDropdown.Item as={Link} to="/resultat-chantier" onClick={() => setActiveItem('')}>Une centrale / Un chantier</NavDropdown.Item>
                                </>
                            </NavDropdown>

                            <div className='app-navbar-user' onClick={handleOpenSidebar}><FontAwesomeIcon icon={faCircleUser} /> <span>Mon profil</span></div>

                            <div onClick={handleOpenSidebar} className={openSidebar ? "profile-content-menu-lateral-bg-filters profile-content-menu-lateral-bg-filters-actif" : "profile-content-menu-lateral-bg-filters"}>
                            </div>

                            <div className={openSidebar ? 'col-xl-2 profile-content-menu-lateral profile-content-menu-lateral-actif' : 'col-xl-2 profile-content-menu-lateral'}>
                                <div className='profile-content-sticky'>
                                    <Link onClick={handleOpenSidebar} className="btnClose" to="#">
                                        <img src={btnClose} alt="" />
                                    </Link>
                                    <div className="profile-content-welcome">
                                        <p className='profil'><FontAwesomeIcon icon={faCircleUser} /></p>
                                        <p className='message-1'>
                                        {!loadUser ?
                                                (user.firstname != null) ?
                                                    `Bonjour ${user.firstname} !`
                                                    : 'Bonjour !'
                                            : ''
                                        }</p>
                                        <p className='message-2'>Bienvenue sur votre profil
                                            {!loadUser ?
                                                (user.role === 'user') ?
                                                    ' - Utilisateur'
                                                    : ` - ${user.role.charAt(0).toUpperCase() + user.role.slice(1)}`
                                            : ''
                                            }
                                        </p>
                                    </div>
                                </div>

                                <div className='profile-content-block-add-button mb-3'>
                                    <NavDropdown className='profile-content-add-button' title={'Je recherche ➔'} >
                                        <>
                                            <NavDropdown.Item as={Link} to="/resultat" onClick={handleOpenSidebar}>Un camion</NavDropdown.Item>
                                            <NavDropdown.Item as={Link} to="/resultat-chantier" onClick={handleOpenSidebar}>Une centrale</NavDropdown.Item>
                                        </>
                                    </NavDropdown>
                                </div>

                                {user.role === 'user' ?
                                    <div className='profile-content-block-add-button'>
                                        <Link to="/profil" state={{ blockToDisplay: 'create_worksite' }}
                                            onClick={handleOpenSidebar} className="profile-content-add-button">Ajouter une annonce +</Link>
                                    </div>
                                    :
                                    <div className='profile-content-block-add-button'>
                                        <Link to="/profil" state={{ blockToDisplay: 'create_product' }}
                                            onClick={handleOpenSidebar} className="profile-content-add-button">Ajouter un camion +</Link>
                                    </div>
                                }

                                <ul className="list-group profile-menu mb-3">
                                    <li className="list-group-item profile-menu-item d-flex lh-sm">
                                        <div className='profile-menu-top'>
                                            <h3>Mon compte</h3>
                                            {user.enabled ?
                                                <p className='profile-menu-top-validation'>Profil vérifié &#x2714;</p>
                                                : ''}
                                        </div>
                                    </li>
                                    <li className='list-group-item profile-menu-item d-flex lh-sm'>
                                        <Link to="/profil" onClick={handleOpenSidebar} state={{ blockToDisplay: 'account_details' }}
                                            className="profile-menu-button my-0">Informations personnelles</Link>
                                    </li>
                                    {user.role === 'Admin' ?
                                        <li className="list-group-item profile-menu-item d-flex lh-sm">
                                            <Link to="/admin" className="profile-menu-button my-0">Administration</Link>
                                        </li> : ''}
                                    <li className="list-group-item profile-menu-item d-flex bg-light">
                                        <h6 className="profile-menu-button my-0" onClick={() => {
                                            handleOpenSidebar();
                                            logOut();
                                        }}>Déconnexion</h6>
                                    </li>
                                </ul>
                            </div>
                        </>
                    ) : (
                        <>
                            {/* <Nav.Link className="hide-mobile hide-tablet nav-link-cta" as={Link} to="/inscription">Je m'inscris</Nav.Link> */}
                            <Nav.Link className="app-navbar-user" as={Link} to="/connexion"><FontAwesomeIcon icon={faCircleUser} /> <span>Je me connecte</span></Nav.Link>
                        </>
                    )}
                </Container>
            </Navbar>
        </>
    );
}

export default Menu;
