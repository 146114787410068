import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, Link } from 'react-router-dom';
import { Col, Container, Row, ProgressBar } from 'react-bootstrap';
import { Formik, Field, Form } from "formik";
import axios from 'axios';


function RegisterOffersPage() {
  const location = useLocation();
  const navigate = useNavigate();

  const { state } = location;

  const [userId, setUserId] = useState(state?.userId || null);
  const [subscription, setSubscription] = useState(state?.subscription || '');
  const [error, setError] = useState('');

  // States pour la sélection d'offre et le contrôle de l'affichage
  const [step, setStep] = useState(6); // étape actuelle pour la barre de progression
  const totalStep = 6;
  const type = "transporteur";
  

  // Montants des offres (en centimes)
  const offerAmounts = {
    pack15:     '22500',     
    pack30:     '30000',     
    serenite12: '24999', 
    serenite24: '19999',
  };

  // Au montage, on extrait les paramètres de l'URL
  useEffect(() => {
    // Vérifier uniquement si l'URL contient des valeurs et que le state n'est pas déjà défini
    const searchParams = new URLSearchParams(location.search);
    const id    = searchParams.get('userId');
    const subs  = searchParams.get('subscription');
    if (id && !userId)          setUserId(id);
    if (subs && !subscription)  setSubscription(subs);
  }, [location.search, userId, subscription]);


  const generateReference = () => {
    // On peut par exemple utiliser "REF" + userId + "_" + timestamp
    return `REF${userId}_${Date.now()}`;
  };


  // Fonction de soumission du formulaire (si nécessaire)
  const handleSubmit = (values) => {
    console.log('Form values submitted:', values);
    // Logique de soumission classique si vous en avez besoin
  };
  
  // À l'intérieur de votre composant SelectionOffre, déclarez ces fonctions :
  const handlePaymentCB = async (e) => {
    e.preventDefault();
    try {
      // Générer le montant et la référence en fonction de l'offre sélectionnée
      const montant = offerAmounts[subscription];
      const reference = generateReference();

      // Définir les URLs de redirection
      const url_OK = `${window.location.origin}/connexion`;
      const url_KO = `${window.location.origin}/selection-offre?userId=${userId}&subscription=${subscription}`;


      // Envoi des données nécessaires pour la demande de paiement.
      const response = await axios.post(
        process.env.REACT_APP_API_URL + `/v1/payment-request`,
        {
          userId,
          subscription,   // Pack (donc le produit)
          montant,        // montant en centimes
          reference,      // référence générée dynamiquement
          url_OK,         // URL quand le paiement se déroule correctement
          url_KO,         // URL en cas d'échec ou d'annulation
        }
      );
      
      console.log(response.data);
      const { redirectUrl } = response.data;
      if (redirectUrl) {
        // Redirection côté navigateur vers l'URL de paiement
        window.location.href = redirectUrl;
      } else {
        setError('Erreur : URL de redirection non définie.');
      }
    } catch (err) {
      console.error('Erreur lors de la demande de paiement :', err);
      setError('Erreur lors de la demande de paiement.');
    }
  };

  const handlePaymentAbonnement = () => {
    // Cette fonction sera développée pour gérer le paiement de l'abonnement
    console.log("Paiement Abonnement déclenché pour l'utilisateur", userId);
  };


  return (
    <div>
      <Formik
        initialValues={{
          firstName: "",
          lastName: "",
        }}
        // validationSchema={SignupSchema} // à décommenter si vous l'utilisez
        onSubmit={handleSubmit}
      >
        {({ values, isValid, touched, errors }) => (
          <Form>
            <div className="register-form-content-items" style={{ display: 'block' }}>
              <Container>
                <Row>
                  <Col md={12} lg={12}>
                    <h2>Abonnement</h2>
                    <p>
                      SoluSpots vous propose plusieurs offres.
                      <br />
                      Celle-ci est modifiable à tout moment dans votre espace personnel.
                    </p>
                    <Row className="mt-5" style={{ alignContent: "stretch" }}>
                      {type === "transporteur" && (
                        <>
                          {/* Packs d'annonces */}
                          <Col sm={12} md={6} xl={4} className="mb-4">
                            <div className="register-price-cards">
                              <h2 className="register-price-cards-title">Packs d'annonces</h2>
                              <hr className="register-price-cards-separator" />
                              <p className="register-price-cards-number">225€ - 300€</p>
                              <ul className="register-price-cards-list">
                                <li className="register-price-cards-list-item">15 Spotmonnaies - 225€</li>
                                <li className="register-price-cards-list-item">30 Spotmonnaies - 300€</li>
                              </ul>
                              <div className="register-price-cards-button">
                                {subscription === 'pack15' ? (
                                  <>
                                    <Link to="#" className="register-button-promo-selected">
                                      Pack 15 annonces sélectionné
                                    </Link>
                                    <button
                                      className="register-button-credit"
                                      type="button"
                                      onClick={() => window.location.href = 'URL_DE_LA_PAGE_DE_PAIEMENT_VISA'}
                                    >
                                      Créditer dès maintenant mon compte
                                    </button>
                                  </>
                                ) : subscription === 'pack30' ? (
                                  <>
                                    <Link to="#" className="register-button-promo-selected">
                                      Pack 30 annonces sélectionné
                                    </Link>
                                    <button
                                      className="register-button-credit"
                                      type="button"
                                      onClick={() => window.location.href = 'URL_DE_LA_PAGE_DE_PAIEMENT_VISA'}
                                    >
                                      Créditer dès maintenant mon compte
                                    </button>
                                  </>
                                ) : (
                                  <>
                                    <button
                                      onClick={() => setSubscription('pack15')}
                                      className="register-button-promo"
                                    >
                                      Je choisis le pack 15 annonces
                                    </button>
                                    <button
                                      onClick={() => setSubscription('pack30')}
                                      className="register-button-promo"
                                    >
                                      Je choisis le pack 30 annonces
                                    </button>
                                  </>
                                )}
                              </div>
                            </div>
                          </Col>

                          {/* Abonnement Sérénité 12 mois */}
                          <Col sm={12} md={6} xl={4} className="mb-4">
                            <div className="register-price-cards">
                              <h2 className="register-price-cards-title">Abonnement Sérénité 12 mois</h2>
                              <hr className="register-price-cards-separator" />
                              <p className="register-price-cards-number">249,99€/mois</p>
                              <ul className="register-price-cards-list">
                                <li className="register-price-cards-list-item">
                                  12 mois - 249,99€/mois avec engagement
                                </li>
                              </ul>
                              <div className="register-price-cards-button">
                                {subscription === 'serenite12' ? (
                                  <Link to="#" className="register-button-promo-selected">
                                    Offre sélectionnée
                                  </Link>
                                ) : (
                                  <button
                                    onClick={() => setSubscription('serenite12')}
                                    className="register-button-promo"
                                  >
                                    Je choisis l'abonnement 12 mois
                                  </button>
                                )}
                              </div>
                            </div>
                          </Col>

                          {/* Abonnement Sérénité 24 mois */}
                          <Col sm={12} md={6} xl={4} className="mb-4">
                            <div className="register-price-cards">
                              <h2 className="register-price-cards-title">Abonnement Sérénité 24 mois</h2>
                              <hr className="register-price-cards-separator" />
                              <p className="register-price-cards-number">199,99€/mois</p>
                              <ul className="register-price-cards-list">
                                <li className="register-price-cards-list-item">
                                  24 mois - 199,99€/mois avec engagement
                                </li>
                              </ul>
                              <div className="register-price-cards-button">
                                {subscription === 'serenite24' ? (
                                  <Link to="#" className="register-button-promo-selected">
                                    Offre sélectionnée
                                  </Link>
                                ) : (
                                  <button
                                    onClick={() => setSubscription('serenite24')}
                                    className="register-button-promo"
                                  >
                                    Je choisis l'abonnement 24 mois
                                  </button>
                                )}
                              </div>
                            </div>
                          </Col>
                        </>
                      )}

                      {type === "betonnier" && (
                        <>
                          {/* Pack 10 annonces */}
                          <Col sm={12} md={6} xl={4} className="mb-4">
                            <div className="register-price-cards">
                              <h2 className="register-price-cards-title">Pack 10 annonces</h2>
                              <hr className="register-price-cards-separator" />
                              <p className="register-price-cards-number">150€</p>
                              <ul className="register-price-cards-list">
                                <li className="register-price-cards-list-item">
                                  10 Spotmonnaies - 150€
                                </li>
                              </ul>
                              <div className="register-price-cards-button">
                                {subscription === 'pack10' ? (
                                  <>
                                    <Link to="#" className="register-button-promo-selected">
                                      Pack 10 annonces sélectionné
                                    </Link>
                                    <button
                                      className="register-button-credit"
                                      type="button"
                                      onClick={() => window.location.href = 'URL_DE_LA_PAGE_DE_PAIEMENT_VISA'}
                                    >
                                      Créditer dès maintenant mon compte
                                    </button>
                                  </>
                                ) : (
                                  <button
                                    onClick={() => setSubscription('pack10')}
                                    className="register-button-promo"
                                  >
                                    Offre sélectionnée
                                  </button>
                                )}
                              </div>
                            </div>
                          </Col>

                          {/* Abonnement "régional" */}
                          <Col sm={12} md={6} xl={4} className="mb-4">
                            <div className="register-price-cards">
                              <h2 className="register-price-cards-title">Abonnement "régional"</h2>
                              <hr className="register-price-cards-separator" />
                              <p className="register-price-cards-number">
                                249,99€/mois avec engagement
                              </p>
                              <ul className="register-price-cards-list">
                                <li className="register-price-cards-list-item">
                                  Audit gratuit des coûts sociaux et fiscaux
                                </li>
                              </ul>
                              <div className="register-price-cards-button">
                                {subscription === 'betonnierPlus' ? (
                                  <>
                                    <Link to="#" className="register-button-promo-selected">
                                      Offre sélectionnée
                                    </Link>
                                    <div className="register-form-field-checkbox mt-2">
                                      <Field id="checkbox-spartesAudit-betonnierPlus" type="checkbox" name="spartesAudit" />
                                      <label htmlFor="checkbox-spartesAudit-betonnierPlus">
                                        Audit gratuit des coûts sociaux et fiscaux avec Spartes.
                                      </label>
                                    </div>
                                  </>
                                ) : (
                                  <Link onClick={() => setSubscription('betonnierPlus')} className="register-button-promo">
                                    Je choisis cette offre
                                  </Link>
                                )}
                              </div>
                            </div>
                          </Col>

                          {/* Abonnement "national" */}
                          <Col sm={12} md={6} xl={4} className="mb-4">
                            <div className="register-price-cards">
                              <h2 className="register-price-cards-title">Abonnement "national"</h2>
                              <hr className="register-price-cards-separator" />
                              <p className="register-price-cards-number">
                                499,99€/mois avec engagement
                              </p>
                              <ul className="register-price-cards-list">
                                <li className="register-price-cards-list-item">
                                  Audit gratuit des coûts sociaux et fiscaux
                                </li>
                              </ul>
                              <div className="register-price-cards-button">
                                {subscription === 'betonnierPro' ? (
                                  <>
                                    <Link to="#" className="register-button-promo-selected">
                                      Offre sélectionnée
                                    </Link>
                                    <div className="register-form-field-checkbox mt-2">
                                      <Field id="checkbox-spartesAudit-betonnierPro" type="checkbox" name="spartesAudit" />
                                      <label htmlFor="checkbox-spartesAudit-betonnierPro">
                                        Audit gratuit avec Spartes.
                                      </label>
                                    </div>
                                  </>
                                ) : (
                                  <Link onClick={() => setSubscription('betonnierPro')} className="register-button-promo">
                                    Je choisis cette offre
                                  </Link>
                                )}
                              </div>
                            </div>
                          </Col>
                        </>
                      )}
                    </Row>
                  </Col>
                </Row>

                <Row>
                  <Col sm={12} className="mb-4">
                    <div className="register-form-field-checkbox">
                      <Field id="checkbox-conformitecgv" type="checkbox" name="conformitecgv" />
                      <label htmlFor="checkbox-conformitecgv">
                        Je confirme avoir pris connaissance des{" "}
                        <a href="/conditions-generales-de-vente" target="_blank" rel="noopener noreferrer">
                          Conditions Générales de Vente
                        </a>.
                      </label>
                    </div>
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <div className="register-footer">
                      <div className="register-progress-container hide-tablet hide-mobile">
                        {/* Exemple de barre de progression */}
                        <ul>
                          <li className="five-steps"></li>
                          <li className="five-steps"></li>
                          <li className="five-steps"></li>
                          <li className="five-steps active"></li>
                          <li className="five-steps"></li>
                          <li className="five-steps"></li>
                        </ul>
                        <ProgressBar className="register-progress-barre" now={(step / totalStep) * 100} />
                      </div>
                      <div className="register-progress-mobile hide-desktop">
                        <ul>
                          <li className="five-steps"></li>
                          <li className="five-steps"></li>
                          <li className="five-steps"></li>
                          <li className="five-steps active"></li>
                          <li className="five-steps"></li>
                          <li className="five-steps"></li>
                        </ul>
                      </div>
                      <div className="register-button">

                        <button
                          className="register-button-next"
                          type="button"
                          onClick={() => {
                            // Vérification simplifiée de la case CGV
                            const cgvChecked = document.getElementById("checkbox-conformitecgv")?.checked;
                            if (cgvChecked) {
                              // Rediriger vers la page de connexion ou une autre page
                              navigate('/connexion');
                            } else {
                              navigate('/connexion');
                            }
                          }}
                        >
                          {document.getElementById("checkbox-conformitecgv")?.checked
                            ? "Valider l'abonnement"
                            : "Passer cette étape"}
                        </button>

                        {userId ? (
                          <p>
                            <strong>$userId: {userId}</strong><br />
                            Choix: {subscription || 'vide'}
                          </p>
                        ) : (
                          <p><strong>$userId vide.</strong></p>
                        )}

                        <button
                          className="button-passer"
                          type="button"
                          onClick={() => navigate('/')}
                        >
                          Passer
                        </button>
                        <button
                          className="button-paiement-cb"
                          type="button"
                          onClick={handlePaymentCB}
                        >
                          Paiement CB
                        </button>
                        <button
                          className="button-paiement-abonnement"
                          type="button"
                          onClick={handlePaymentAbonnement}
                        >
                          Paiement Abonnement
                        </button>

                      </div>
                    </div>
                  </Col>
                </Row>
              </Container>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default RegisterOffersPage;
