import React, { useState, createContext, useContext, useEffect } from 'react';
import { Route, Routes, useLocation, Navigate } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { Capacitor } from '@capacitor/core';
import OneSignal from 'onesignal-cordova-plugin';
import UpdateOneSignalId from './components/tools/UpdateOneSignalID';
import HomePage from './pages/HomePage';
import OffersPage from './pages/OffersPage';
import RegisterPage from './pages/RegisterPage';
import RegisterOffersPage from './pages/RegisterOffersPage';
import LoginPage from './pages/LoginPage';
import ProfilePage from './pages/ProfilePage';
import ProductPage from './pages/ProductPage';
import ResultPage from './pages/ResultPage';
import CataloguePage from './pages/CataloguePage';
import CategoriesPage from './pages/CategoriesPage';
import NotFoundPage from './pages/NotFoundPage';
import ContactPage from './pages/ContactPage';
import Menu from './components/Menu';
import Footer from './components/Footer';
import DiscoverPage from "./pages/DiscoverPage";
import CompanyPage from './pages/CompanyPage';
import ResetPasswordPage from './pages/ResetPasswordPage';
import ResetPasswordConfirmPage from './pages/ResetPasswordConfirmPage';
import CreatePasswordPage from './pages/CreatePasswordPage';
import CreatePasswordConfirmPage from './pages/CreatePasswordConfirmPage';
import PolitiqueDeConfidentialitePage from './pages/PolitiqueDeConfidentialitePage';
import WorksitePage from './pages/WorksitePage';
import ResultWorksitePage from './pages/ResultWorksitePage';
import TransporterPage from './pages/TransporterPage';
import BetonPage from './pages/BetonPage';
import LegalNoticePage from './pages/LegalNoticePage';
import TermsOfServicePage from './pages/TermsOfServicePage';
import TermsOfSalesPage from './pages/TermsOfSalesPage';
import PrivacyPolicyPage from './pages/PrivacyPolicyPage';
import ChartePage from './pages/ChartePage';
import RegisterRequest from './pages/RegisterRequest';
import SoluSpotsPage from './pages/SoluSpotsPage';
import AboutPage from './pages/AboutPage';
import TestMoneticoPage from './pages/TestMoneticoPage';

export const AuthContext = createContext(null);

export const useAuth = () => {
    return useContext(AuthContext);
};

export const ReactApp = () => {
    const { user } = useAuth();
    const location = useLocation();
    const currentUrl = location.pathname;
    const [activeMenuItem, setActiveMenuItem] = useState('');

    useEffect(() => {        
        if (Capacitor.isNativePlatform()) {
            OneSignal.Debug.setLogLevel(6);
            OneSignal.initialize("298ebce9-76bc-4a50-bea7-d34ee1cd7bec");

            OneSignal.Notifications.addEventListener('click', async (e) => {
                let clickData = await e.notification;
                console.log("Notification Clicked : " + clickData);
            })

            OneSignal.Notifications.requestPermission(true).then((success) => {
                console.log("Notification permission granted " + success);
            })
        }
    }, []);

    return (
        <>
            {currentUrl !== '/connexion' && currentUrl !== '/connexion/'
                && currentUrl !== '/profil' && currentUrl !== '/profil/'
                && currentUrl !== '/inscription' && currentUrl !== '/inscription/'
                && currentUrl !== '/mot-de-passe-oublie' && currentUrl !== '/mot-de-passe-oublie/'
                && currentUrl.split('/')[1] !== 'confirmation-mot-de-passe-oublie' && currentUrl.split('/')[1] !== 'confirmation-mot-de-passe-oublie/'
                ? <Menu activeItem={activeMenuItem} setActiveItem={setActiveMenuItem} />
                : ''}

            <Routes>
                <Route path='/' exact element={<HomePage />} />
                <Route path='/offres' exact element={<OffersPage />} />
                <Route path='/politique-de-confidentialite' exact element={<PrivacyPolicyPage />} />
                <Route path='/charte' exact element={<ChartePage />} />
                <Route path='/profil' exact element={<ProfilePage activeItem={activeMenuItem} setActiveItem={setActiveMenuItem} />} />
                <Route path='/offres' exact element={<OffersPage />} />
                <Route path='/devenir-loueur' exact element={<DiscoverPage />} />
                <Route path='/transporteur' exact element={<TransporterPage />} />
                <Route path='/betonnier' exact element={<BetonPage />} />
                <Route path='/SoluSpotsPage' exact element={<SoluSpotsPage />} />
                <Route path='/resultat' exact element={<ResultPage />} />
                <Route path='/resultat-chantier' exact element={<ResultWorksitePage />} />
                <Route path='/contact' exact element={<ContactPage />} />
                <Route path='/entreprise/:id' exact element={<CompanyPage />} />
                <Route path='/catalogue' exact element={<CataloguePage />} />
                <Route path='/categories/:categ' exact element={<CategoriesPage />} />
                <Route path='/categories/:categ/:product' exact element={<ProductPage />} />
                <Route path='/chantiers/:worksite' exact element={<WorksitePage />} />
                <Route path='*' element={<NotFoundPage />} />
                {user ? <Route path="/connexion" element={<Navigate to="/profil" replace />} /> : <Route path="/connexion" exact element={<LoginPage />} />}
                {user ? <Route path="/inscription" element={<Navigate to="/profil" replace />} /> : <Route path="/inscription" exact element={<RegisterPage />} />}
                {user ? <Route path="/demande-inscription" element={<Navigate to="/profil" replace />} /> : <Route path="/demande-inscription" exact element={<RegisterRequest />} />}
                {user ? <Route path="/mot-de-passe-oublie" element={<Navigate to="/profil" replace />} /> : <Route path='/mot-de-passe-oublie' exact element={<ResetPasswordPage />} />}
                {user ? <Route path="/creation-mot-de-passe" element={<Navigate to="/profil" replace />} /> : <Route path='/creation-mot-de-passe' exact element={<CreatePasswordPage />} />}
                {user ? <Route path="/confirmation-mot-de-passe-oublie/:token/:email" element={<Navigate to="/profil" replace />} /> : <Route path='/confirmation-mot-de-passe-oublie/:token/:email' exact element={<ResetPasswordConfirmPage />} />}
                {user ? <Route path="/changement-mot-de-passe/:token/:email" element={<Navigate to="/profil" replace />} /> : <Route path='/changement-mot-de-passe/:token/:email' exact element={<CreatePasswordConfirmPage />} />}
                {user ? <Route path="/politique-de-confidentialite" element={<Navigate to="/profil" replace />} /> : <Route path='/politique-de-confidentialite' exact element={<PolitiqueDeConfidentialitePage />} />}
                <Route path='/selection-offre' exact element={<RegisterOffersPage />} />
                <Route path='/mentions-legales' exact element={<LegalNoticePage />} />
                <Route path='/conditions-generales-utilisation' exact element={<TermsOfServicePage />} />
                <Route path='/conditions-generales-de-vente' exact element={<TermsOfSalesPage />} />
                <Route path="/a-propos" exact element={<AboutPage />} />
                <Route path="/test-monetico" exact element={<TestMoneticoPage />} />
            </Routes>

            <ToastContainer />

            <UpdateOneSignalId user={user} />

            {currentUrl !== '/connexion' && currentUrl !== '/connexion/'
                && currentUrl !== '/profil' && currentUrl !== '/profil/'
                && currentUrl !== '/inscription' && currentUrl !== '/inscription/'
                && currentUrl !== '/demande-inscription' && currentUrl !== '/demande-inscription/'
                && currentUrl !== '/mot-de-passe-oublie/' && currentUrl !== '/mot-de-passe-oublie'
                && currentUrl.split('/')[1] !== 'confirmation-mot-de-passe-oublie' && currentUrl.split('/')[1] !== 'confirmation-mot-de-passe-oublie/'
                ? <Footer />
                : ''}
        </>
    )
}
